<template>
  <div class="flex-1 container">
    <!-- <div class="column flex-1 intro">
      <h2>Send us a message</h2>
      <p>We aim to respond as quickly as possible&hellip;</p>
      <p>Consider checking our
        <router-link to="/contact/faqs">FAQs</router-link>
        for answers to some of the most common questions.
      </p>
    </div>
    <div class="column width-2-3 width-full-medium width-full-small">
      <form v-on:submit.prevent="onSubmit" class="form">
        <div v-show="sending" class="loader-mask">
          <div class="loader"/>
        </div>
        <p v-if="success" class="success">Your message was sent</p>
        <p v-else-if="error" v-html="errorMessage" class="error"></p>
        <label>Your name</label>
        <input v-model="data.name" type="text" name="name" maxlength="255" placeholder="Name" autocomplete="name" required/>
        <label>Your email address</label>
        <input v-model="data.email" type="email" name="email" maxlength="255" placeholder="Email address" autocomplete="email" required/>
        <label>Subject</label>
        <select v-model="data.subject" name="subject" title="Please select a subject" required>
          <option value="">Please select&hellip;</option>
          <option value="I have a general question">I have a general question</option>
          <option value="I would like to buy a print">I would like to buy a print</option>
          <option value="I bought a keyring and didn't receive it">I bought a keyring and didn't receive it</option>
          <option value="I have a suggestion">I have a suggestion</option>
          <option value="I would like to discuss advertising">I would like to discuss advertising</option>
          <option value="I would like to hire Smileonthetiles for an event">I would like to hire Smileonthetiles for an event</option>
        </select>
        <label>Message</label>
        <textarea v-model="data.message" name="message" placeholder="Message..." rows="5"></textarea>
        <p v-if="captchaMissing" class="info">Please prove you are not a robot 🤖</p>
        <div class="flexbox flexbox-align-end flexbox-space-between flexbox-wrap">
          <div>
            <div id="g-recaptcha"></div>
          </div>
          <button type="submit" class="block">Submit</button>
        </div>
        <input v-model="data.verification" type="hidden" name="verification" readonly/>
      </form>
    </div> -->
    <div class="column width-full extra">
      <h2>Send us a message</h2>
      <a href="mailto:info@smileonthetiles.co.uk" class="block"><i class="material-icons">mail</i>info@smileonthetiles.co.uk</a>
      <a href="https://www.facebook.com/smileonthetilesplymouth/" class="block facebook" target="_blank" rel="noopener"><i class="fab fa-facebook fa-fw"></i>Facebook</a>
      <a href="skype:smileonthetiles?call" class="block skype"><i class="fab fa-skype fa-fw"></i>@smileonthetiles</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'contact-home',
    data() {
      return {
        captcha: null,
        captchaMissing: false,
        error: false,
        errorMessage: 'An error occurred',
        success: false,
        sending: false,
        data: {
          name: this.$store.state.user.displayName || '',
          email: this.$store.state.user.email || '',
          subject: '',
          message: '',
          verification: ''
        }
      }
    },
    mounted() {
      /* global grecaptcha */
      this.captcha = grecaptcha.render( 'g-recaptcha', {
        sitekey: '6LfadFcUAAAAAJFas2B5UKEVIHrY1iSHUILaLi4p'
        // theme: 'dark',
        // size: 'compact'
      } )
    },
    methods: {
      onSubmit() {
        this.captchaMissing = false

        /* global grecaptcha */
        this.data.verification = grecaptcha.getResponse( this.captcha )

        if( this.data.verification ) {
          this.send()
        } else {
          this.captchaMissing = true
        }
      },
      send() {
        //TODO: FetchPOST
        /* global Request */
        let request = new Request( this.$root.apiURL + '/contact', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data'
          },
          body: JSON.stringify( this.data )
        } )

        this.success = false
        this.sending = true
        this.error = false

        /* global fetch */
        fetch( request )
          .then( response => {
            if( response.ok ) {
              return response
            } else {
              throw response
            }
          } )
          .then( response => response.json() )
          .then( json => {
            if( json.error === false ) {
              this.onSuccess()
            } else {
              this.error = true
              this.errorMessage = json.message
              if( json.captchaerror !== false ) {
                grecaptcha.reset()
              }
            }
            this.sending = false
          } )
          .catch( e => {
            console.error( e )
            this.error = true
            this.errorMessage = e.message
            this.sending = false
            this.captchaMissing = false
            grecaptcha.reset()
          } )
      },
      onSuccess() {
        this.success = true
        this.data.name = this.$store.state.user.displayName || ''
        this.data.email = this.$store.state.user.email || ''
        this.data.subject = ''
        this.data.message = ''
        grecaptcha.reset()
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";

  .intro {
    margin-bottom: $gutter;
  }

  .extra {
    margin-top: $gutter;

    .block {
      margin-top: 1rem;
      padding-left: 7rem;

      display: block;

      position: relative;

      color: $base-font-colour;

      background-color: #FFF;

      &.facebook,
      &.skype {
        color: #FFF;
      }

      &.facebook {
        background-color: $facebook;
      }

      &.skype {
        background-color: $skype;
      }

      &:hover {
        color: #FFF;

        background-color: $base-font-colour;
      }

      .fab,
      .material-icons {
        margin-top: -1.5rem;

        position: absolute;
        top: 50%;
        left: 2rem;

        font-size: 3rem;
      }
    }
  }
</style>
