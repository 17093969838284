<template>
  <div class="flex-1 container">
    <div class="column flex-1">
      <h2>FAQs</h2>
      <p>These are answers to the questions we most regularly get asked. If you have a question which hasn't been answered here, please feel free to contact us where we will be happy to respond to the best of our ability.</p>
      <template v-for="faq in faqs">
        <hr>
        <h3 v-html="faq.q"></h3>
        <div v-html="faq.a"></div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'contact-faqs',
    data() {
      return {
        faqs: [
          {
            q: 'Is this service really FREE?',
            a: '<p>YES! We do not charge anything for using smileonthetiles.co.uk.</p><p>It\'s free to register and download photos, and always will be. That is the whole aim of our service. We charge for keyrings, merchandise and copies of the best quality images.</p>'
          },
          {
            q: 'What will you do with my photo?',
            a: '<p>All your photos are stored publicly on our site and you are free to browse, download and share any of them.</p><p>Every week, we select a few choice photos to appear in the Plymouth Herald and on the <a href="https://www.plymouthherald.co.uk/whats-on/music-nightlife/" rel="noopener" target="_blank">Plymouth Herald website</a>.</p>'
          },
          {
            q: 'Where are my photos from this weekend?',
            a: '<p>We take a huge amount of photos every night and sometimes there can be a delay posting the photos online. If you keep checking back, you should find your photos have been added within a few days time!</p><p>If you are seriously desperate, you may want to take a look at some of the venues\' own Facebook pages where you can also find a lot of the more recent photos.</p>'
          },
          {
            q: 'Didn\'t get your keyring?',
            a: '<p>Our photographers work hard to try to reunite you with your keyring. Your keyring will have been left in a safe place inside the venue it was purchased from. Try talking to one of our photographers when you\'re next on the town, or get in touch.</p>'
          },
          // {
          //   q: 'Where can I get my picture taken?',
          //   a: '<p>We operate regularly in a number of clubs. Try checking out some of our existing photos on the site to get an idea of which clubs we visit&hellip;</p>'
          // },
          {
            q: 'Is ALEX defeatable?',
            a: '<p>Many punters have attempted to defeat the Smileonthetiles boss level — Alex, although have failed in their attempts.</p><p>To this day, Alex remains undefeated as Master Photographer and shall continue to reign supreme until a skillful young grasshopper snatches the keyring from his palm&hellip;</p>'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../styles/env";
</style>
