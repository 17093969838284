<template>
  <div class="page flex-1 flexbox-vertical">
    <header class="header">
      <div class="container">
        <div class="column full">
          <h1>Contact Us</h1>
        </div>
      </div>
    </header>
    <div class="flexbox flex-1">
      <div class="flex-1 primary-background-colour-grad"></div>
      <div class="container no-padding block-on-mobile">
        <div class="flex-1 sidebar primary-background-colour-grad">
          <router-link to="/contact">Contact methods</router-link>
          <router-link to="/contact/faqs">FAQs</router-link>
          <!-- <div class="hide-on-mobile">
            <h4>Contact details</h4>
            <a href="mailto:info@smileonthetiles.co.uk">info@smileonthetiles.co.uk</a>
            <a href="https://www.facebook.com/smileonthetilesplymouth/" target="_blank" rel="noopener">Facebook</a>
            <a href="skype:smileonthetiles?call">Skype @smileonthetiles</a>
          </div> -->
        </div>
        <main class="flex-3">
          <router-view></router-view>
        </main>
      </div>
      <div class="flex-1 white-background-colour"></div>
    </div>
    <site-footer/>
  </div>
</template>

<script>
  import SiteFooter from '../components/SiteFooter.vue'

  export default {
    name: 'contact',
    components: { SiteFooter }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../styles/env";
</style>
